import React, { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import ComponentLoader from './component-loader'
import AppLayout from 'screens/AppLayout'
import UserCode from 'screens/pass/usercode'

const Profile = ComponentLoader(lazy(() => import('screens/Profile/profile')))

const Pass = ComponentLoader(lazy(() => import('screens/pass')))
const Passes = ComponentLoader(lazy(() => import('screens/pass/passes')))

const PassDetail = ComponentLoader(
  lazy(() => import('screens/pass/pass-details'))
)

const BackFields = ComponentLoader(
  lazy(() => import('screens/pass/back-fields'))
)

const PublicRoutes = [
  {
    path: 'user',
    element: <AppLayout />,
    children: [
      {
        path: 'profile',
        element: (
          <div className="max-w-3xl mx-auto px-8">
            <Profile />
          </div>
        ),
      },
      {
        path: 'wallet',
        element: <Pass />,
        children: [
          {
            path: '',
            element: <Passes />,
          },
          {
            path: 'pass',
            children: [
              {
                path: '',
                index: true,
                element: <PassDetail />,
              },
              {
                path: 'backfields',
                element: <BackFields />,
              },
            ]
          },
          {
            path: 'mypass',
            children: [
              {
                path: '',
                index: true,
                element: <PassDetail />,
              },
              {
                path: 'backfields',
                element: <BackFields />,
              },
            ]
          },
          {
              path: 'code',
              element: <UserCode />,
          },
        
        ],
      },
      {
        path: '*',
        element: <Navigate to={'/user/wallet'} />,
      },
      {
        path: '',
        element: <Navigate to={'/user/wallet'} />,
      },
    ],
  },
]

export default PublicRoutes
