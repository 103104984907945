import { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import AppLayout from 'screens/AppLayout'
import Mapscreen from 'screens/mapscreen/mapscreen'
import ComponentLoader from './component-loader'

const Home = ComponentLoader(lazy(() => import('screens/Home/home')))

const LocationDetails = ComponentLoader(
  lazy(() => import('screens/Location-details/location-details'))
)

const LocationGrowthtool = ComponentLoader(
  lazy(() => import('screens/Public/location-growthtool'))
)

const PublicRoutes = [
  {
    path: '',
    element: <AppLayout />,
    children: [
      {
        path: '',
        element: <Home />,
      },
      {
        path: 'location',
        element: <LocationDetails />,
      },
      {
        path: 'map-screen',
        element: <Mapscreen />,
      },
      // {
      //   path: 'registration',
      //   element: <RegisterPass />
      // },
      {
        path: 'user/wallet',
        element: <Navigate to={'/auth/login'} />,
      },
      {
        path: '*',
        element: <Navigate to={''} />,
      },
    ],
  },
  {
    path: 'location/:gtcode',
    element: <LocationGrowthtool />,
  },
]

export default PublicRoutes
