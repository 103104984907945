import { useAgency } from 'context/agency'
import { useAuth } from 'context/auth'
import { PREMIUM_APPS } from 'enums'
import HomeIcon from 'icons/home'
import SearchIcon from 'icons/search'
import WalletIcon from 'icons/wallet'
import React from 'react'
import { Link } from 'react-router-dom'

function NavButton({ path, Icon, label, state, disabled }) {
  const { style } = useAgency()
  return (
    <Link
      type='button'
      to={disabled ? '' : path}
      className={`inline-flex flex-col items-center justify-center px-5  group ${
        disabled ? 'opacity-40 cursor-not-allowed' : 'hover:bg-gray-50'
      }`}
      state={state}
      disabled={disabled}
      onClick={(event) => disabled && event.preventDefault()}
    >
      {
        <Icon
          style={{
            fill:
              window.location.pathname == path
                ? style.text.active_tabs_icon
                : style.text.tabs_icon,
            stroke:
              window.location.pathname == path
                ? style.text.active_tabs_icon
                : style.text.tabs_icon,
          }}
        />
      }
      <span
        style={{
          color:
            window.location.pathname == path
              ? style.text.active_text_icon
              : style.text.tabs_text,
        }}
        class='text-sm text-inherit group-hover:text-blue-600'
      >
        {label}
      </span>
    </Link>
  )
}

function BottomNavigation() {
  const { currentUser } = useAuth()
  const { style, agency } = useAgency()

  const isAppExist = (app) =>
    agency?.premium_apps?.find((item) =>
      item.upsale_id?.products?.includes(app)
    )

  return (
    <div
      style={{ backgroundColor: style.background.bottom_nav_color }}
      class={`fixed bottom-0 left-0 z-50 w-full h-16 bg-white border-t border-gray-200 `}
    >
      <div
        style={{
          color: style.text.active_tab || style.text.tabs_text,
        }}
        class='grid h-full max-w-lg grid-cols-3 mx-auto font-medium'
      >
        <NavButton label={'Search'} Icon={SearchIcon} path={'/'} />
        <NavButton
          label={'My Pass'}
          Icon={HomeIcon}
          path={
            isAppExist(PREMIUM_APPS.citypass)
              ? currentUser
                ? '/user/wallet/mypass'
                : '/auth'
              : currentUser
              ? '/user/wallet/code'
              : '/auth'
          }
          // disabled={!isAppExist(PREMIUM_APPS.citypass)}
        />
        <NavButton
          label={'My Wallet'}
          Icon={WalletIcon}
          path={'/user/wallet'}
        />
      </div>
    </div>
  )
}

export default BottomNavigation
