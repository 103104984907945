import { Environment } from '../enums'

const env = {
  API_URL: 'http://localhost:8082/api',
  G_MAP_API_KEY: 'AIzaSyC7Bj8PVfTnNIm8j2x7zEXT0-4bckwiM00',
}

if (process.env.REACT_APP_ENV === Environment.STAGING) {
  env.API_URL = window.location.hostname.includes('dev.walletapp.wallet')
    ? 'https://dev.api.walletcampaigns.com/api'
    : `${window.location.origin}/server/api`
}

if (process.env.REACT_APP_ENV === Environment.PRODUCTION) {
  env.API_URL = window.location.hostname.includes('wallet.wallet')
    ? 'https://api.walletcampaigns.com/api'
    : `${window.location.origin}/server/api`
}

export default env
