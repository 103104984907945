import React from 'react'

function SearchIcon(props) {
  return (
    <svg
      class='w-5 h-5 mb-2 text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      stroke-width='2'
      stroke='currentColor'
      fill='none'
      stroke-linecap='round'
      stroke-linejoin='round'
      {...props}
    >
      {' '}
      <path stroke='none' d='M0 0h24v24H0z' fill='none' />{' '}
      <circle cx='10' cy='10' r='7' fill='none' />{' '}
      <line x1='21' y1='21' x2='15' y2='15' />
    </svg>
  )
}

export default SearchIcon
