import * as USER from './user'

const Environment = {
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production',
}

const PREMIUM_APPS = {
  chat: 'chat',
  ticket: 'ticket',
  docs: 'docs',
  citypass: 'citypass',
}

export { USER, Environment, PREMIUM_APPS }
