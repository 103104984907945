import { useAuth } from 'context/auth'

function AppLogo() {
  const { currentUser } = useAuth()

  return (
    <div className="flex items-center justify-start rtl:justify-end">
      <a href="/" className="flex ms-2 md:me-24">
        <img
          src={currentUser.avatar || 'https://placehold.co/600x600'}
          className="w-8 h-8 rounded-full object-cover me-3"
          alt="FlowBite Logo"
        />
        <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap">
          {currentUser.fullname || 'User Name'}
        </span>
      </a>
    </div>
  )
}

export default AppLogo
