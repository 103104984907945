import BottomNavigation from 'components/BottomNavigation/BottomNavigation'
import Header from 'components/Header/header'
import { useAgency } from 'context/agency'
import React from 'react'
import { Outlet } from 'react-router-dom'

function AppLayout() {
  const { style } = useAgency()

  return (
    <div
      className='h-[100vh] overflow-y-auto'
      style={{
        backgroundColor: style.background.page,
      }}
    >
      <Header />
      <main className='max-w-4xl mx-auto pb-16'>
        <Outlet />
      </main>
      <BottomNavigation />
    </div>
  )
}

export default AppLayout
