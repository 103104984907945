import Loader from "components/loader"
import { useAgency } from "context/agency"
import { QRCodeCanvas } from "qrcode.react"
import { useEffect, useState } from "react"
import Api from "utils/api"

function UserCode() {
    const { agency } = useAgency()
    const [code, setCode] = useState('')
    const [loading, setLoading] = useState(true)
  
    useEffect(() => {
      Api('/user/agency/subscriber?agency_id=' + agency._id, 'get')
        .then(({ response }) => {
          setCode(response?.data?.subscriber_detail.user_code)
          setLoading(false)
        })
        .catch((err) => setLoading(false))
    }, [])
  
    return (
      <div
        className='fixed top-0 left-0 w-full h-full flex items-center justify-center'
        // onClick={closeModal}
      >
        <div className=''>
          {/* Modal content */}
          <div className='flex items-center justify-center p-4 md:p-5 border-b rounded-t'>
            <h3 className='text-xl font-semibold text-gray-900'>
              <span>Your Unique ID</span>
            </h3>
            {/* <button
              onClick={closeModal}
              type='button'
              className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center'
            >
              <CloseIcon />
              <span className='sr-only'>Close modal</span>
            </button> */}
          </div>
          {loading ? (
            <div className='h-56 flex justify-center items-center flex-col'>
              <Loader />
            </div>
          ) : code ? (
            <div>
              <QRCodeCanvas className='mx-auto mt-10' value={code} />
              <p className='text-center mb-8 mt-4 font-semibold text-lg'>
                {code}
              </p>
            </div>
          ) : (
            <div className='h-56 flex justify-center items-center flex-col'>
              <div className='text-4xl font-bold '>404</div>
              <div className='text-xl font-bold mt-4'>No Code Found!</div>
              <p>Please contact your support admin</p>
            </div>
          )}
          {/* <div class='flex items-center p-4 border-t border-gray-200 rounded-b '>
            <ButtonGradient type={'button'} label={'Okay'} onClick={closeModal} />
          </div> */}
        </div>
      </div>
    )
  }


export default UserCode