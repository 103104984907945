import SecondaryBtn from 'components/Buttons/secondary-btn'
import ProfileButton from 'components/profile-button'
import { useAgency } from 'context/agency'
import { useAuth } from 'context/auth'
import AccountIcon from 'icons/account'
import BackIcon from 'icons/back'
import LoginIcon from 'icons/login'
import React, { useEffect, useState } from 'react'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'

function Header() {
  const navigate = useNavigate()
  const { agency, style } = useAgency()
  const { currentUser } = useAuth()
  const { pathname } = useLocation()
  const routeIndex = pathname.split('/').pop()
  const [showHeader, setShowHeader] = useState(false)
  const { state: location = {} } = useLocation()

  useEffect(() => {
    console.log('Path Length:', routeIndex)

    setShowHeader(!routeIndex)
  }, [pathname, routeIndex])
  return (
    <div
      class='p-5'
      style={{
        backgroundColor: style.background.top_header,
        color: style.text.top_header,
      }}
    >
      <div class='flex justify-between items-center'>
        {showHeader && (
          <>
            <div>
              <img
                src={
                  agency?.wallet_config?.logo || 'https://placehold.co/600x600'
                }
                alt='logo'
                className='rounded-full w-11'
              />
            </div>
            <div className='text-center'>
              {agency?.wallet_config?.title || 'Agency'}
            </div>
          </>
        )}
        {!showHeader && (
          <>
            <div onClick={() => navigate(-1)}>
              <BackIcon />
            </div>
            <div className='uppercase'>
              <h1>{location?.name || routeIndex}</h1>
            </div>
          </>
        )}

        <div className='flex justify-end'>
          {currentUser ? (
            <ProfileButton />
          ) : (
            <Link to={'/auth/login'}>
              <SecondaryBtn>
                <div className='flex items-center'>
                  <LoginIcon size={13} /> <span className='ml-2'>Signin</span>
                </div>
              </SecondaryBtn>
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

export default Header
