import React, { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import ComponentLoader from './component-loader'
import AppLayout from 'screens/AppLayout'

const SignUp = ComponentLoader(lazy(() => import('screens/Sign-up/sign-up')))
const SignIn = ComponentLoader(lazy(() => import('screens/Sign-in/sign-in')))
const ChangePass = ComponentLoader(
  lazy(() => import('screens/Sign-in/changepass/email'))
)
const Pass = ComponentLoader(
  lazy(() => import('screens/Sign-in/changepass/newpass'))
)

const AuthRoutes = [
  {
    path: 'auth',
    element: <AppLayout />,
    children: [
      {
        path: 'signup',
        element: <SignUp />,
      },
      {
        path: 'login',
        element: <SignIn />,
      },
      {
        path: 'forget-pass',
        children: [
          {
            path: '',
            index: true,
            element: <ChangePass />,
          },
          {
            path: 'reset-pass',
            element: <Pass />,
          },
        ],
      },
      {
        path: '*',
        element: <Navigate to={'/auth/login'} />,
      },
      {
        path: '',
        element: <Navigate to={'/auth/login'} />,
      },
    ],
  },
]

export default AuthRoutes
