import React, { useState, useEffect, createContext, useContext } from 'react'
import Api from 'utils/api'
import Loader from 'components/loader'

const initAgencyContextPropsState = {
  loading: false,
  agency: undefined,
  setAgency: () => {},
  getAgency: async () => {},
  style: {
    cover: 'https://placehold.co/10x10',
    logo: 'https://placehold.co/10x10',
    background: {
      button_bg_color: '#D4E3D6',
      button_color: '#D4E3D6',
      card_color: '#1A73E8',
      page: '#C4E3D6',
      top_header: '#1A73E8',
      card_button: '#1A73E8',
      // bottom_nav_color: '#1A73E8',
    },
    text: {
      button_text_color: '#D4E3D6',
      top_header: '#ffffff',
      card: '#ffffff',
      headings: '#ffffff',
      active_tabs_icon: '#847171',
      active_text_icon: '#D04200',
      tabs_text: '#847171',
      tabs_icon: '#847171',
      // button_text: '#345171',
    },
  },
}

const AgencyContext = createContext(initAgencyContextPropsState)

const useAgency = () => {
  return useContext(AgencyContext)
}

const AgencyProvider = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const [agency, setAgency] = useState([])

  const getPremiumApps = async (agency_id) => {
    const { response } = await Api(
      '/agency/premium_apps?agency_id=' + agency_id,
      'get'
    )
    if (response) return response.data
  }

  const getAgency = async () => {
    const { response } = await Api(
      '/agency/domain/wallet_app?domain=' + window.location.hostname,
      'get'
    )
    if (response) {
      // const premium_apps = await getPremiumApps(response.data._id)
      // setAgency({ ...response.data, premium_apps })
      setAgency(response.data)
    } else setAgency({})

    setLoading(false)
  }

  const onLoad = () => {
    getAgency()
  }

  useEffect(onLoad, [])

  const style = {
    cover: agency?.wallet_config?.cover,
    logo: agency?.wallet_config?.logo,
    background: {
      page: agency?.wallet_config?.page_bg_color,
      button_bg_color: agency?.wallet_config?.button_bg_color,
      top_header: agency?.wallet_config?.top_header_bg,
      card_button: agency?.wallet_config?.card_button_bg_color,
      button_color: agency?.wallet_config?.button_color,
      card_color: agency?.wallet_config?.card_bg_color,
      bottom_nav_color: agency?.wallet_config?.bottomnav_bg_color,
    },
    text: {
      button_text_color: agency?.wallet_config?.button_text_color,
      active_tabs_icon: agency?.wallet_config?.bottomnav_active_color,
      headings: agency?.wallet_config?.card_button_bg_color,
      card: agency?.wallet_config?.card_text_color,
      tabs_text: agency?.wallet_config?.bottomnav_text_color,
      tabs_icon: agency?.wallet_config?.bottomnav_text_color,
      top_header: agency?.wallet_config?.top_header_text,
      active_text_icon: agency?.wallet_config?.bottomnav_active_color,
      button_text: agency?.wallet_config?.card_button_text_color,
    },
  }

  return (
    <AgencyContext.Provider
      value={{
        loading,
        agency,
        setAgency,
        getAgency,
        style,
      }}
    >
      {loading ? <Loader /> : children}
    </AgencyContext.Provider>
  )
}

export { AgencyProvider, useAgency }
