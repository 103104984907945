import Routes from 'routing/routes'
import { useRoutes } from 'react-router-dom'
import './App.css'
import { AuthInit } from 'context/auth'
import useScript from 'hooks/script'
import env from 'configuration'

function App() {
  const routes = useRoutes(Routes())
  const scriptLoaded = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${env.G_MAP_API_KEY}&loading=async&libraries=places`
  )

  return <AuthInit>{routes}</AuthInit>
}

export default App
