import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
} from 'react'
import * as authHelper from 'utils/auth-helper'
import Api from 'utils/api'
import Loader from 'components/loader'
import { useAgency } from './agency'

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  memberPass: undefined,
  setMemberPass: () => {},
  logout: () => {},
}

const AuthContext = createContext(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState()
  const [memberPass, setMemberPass] = useState()

  const saveAuth = (auth) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        memberPass,
        setMemberPass,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit = ({ children }) => {
  const { auth, logout, setCurrentUser, setMemberPass } = useAuth()
  const { agency } = useAgency()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  const getMemberPass = async (user) => {
    console.log('Getting Memeber Pass')
    const member = await Api(
      `/pass/member/pass/json?agency_id=${agency._id}`,
      'get'
    )
    if (member.response) {
      setMemberPass(member.response.data)
    }
    // if (!member.response) {
    //   registerMemberPass(user)
    // }
  }

  const registerMemberPass = async (user) => {
    console.log('Memeber Pass Not Exist, Creating...')
    const payload = {
      email: user.email,
      phone: user.phone,
      firstname: user.firstname,
      lastname: user.lastname,
      agency_id: agency._id,
    }

    const { response } = await Api('/auth/walletapp_signup', 'post', {
      payload,
    })

    if (response) getMemberPass(user)
  }

  useEffect(() => {
    const requestUser = async () => {
      try {
        if (didRequest.current != auth) {
          setShowSplashScreen(true)
          const { response } = await Api('/auth/verify_token', 'get')
          if (response) {
            setCurrentUser(response.data)
            registerMemberPass(response.data)
            // getMemberPass(response.data)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      didRequest.current = auth
    }

    if (auth && auth.token) {
      requestUser(auth.token)
    } else {
      logout()
      setShowSplashScreen(false)
    }
  }, [auth])

  return showSplashScreen ? (
    <div className='w-full h-[100vh] flex flex-col items-center justify-center'>
      <Loader />
    </div>
  ) : (
    <>{children}</>
  )
}

export { AuthProvider, AuthInit, useAuth }
