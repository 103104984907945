import React from 'react'

function Mapscreen() {
  return (
    <>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6502595.146296284!2d-119.306607!3d37.2691675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb9fe5f285e3d%3A0x8b5109a227086f55!2sCalifornia%2C%20USA!5e0!3m2!1sen!2s!4v1700233990088!5m2!1sen!2s"
        width="100%"
        height="530px"
        style={{ border: '0' }}
        allowfullscreen=""
        // loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </>
  )
}

export default Mapscreen
