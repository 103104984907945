import React from 'react'

function SecondaryBtn({ onClick, label, children, className }) {
  return (
    <button
      onClick={onClick}
      type='button'
      className={
        className +
        ' ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10'
      }
    >
      {label || children}
    </button>
  )
}

export default SecondaryBtn
